export class Material {
  constructor(readonly id: string, readonly name : string, readonly cost : number) {

  }
}

const values = [
  new Material(crypto.randomUUID(), "Opal", 15),
  new Material(crypto.randomUUID(), "Kamari Pearl", 19),
  new Material(crypto.randomUUID(), "Honey 03", 19),
  new Material(crypto.randomUUID(), "Basel Pearl", 21),
  new Material(crypto.randomUUID(), "Sina", 22),
  new Material(crypto.randomUUID(), "Awangarda", 27),
  new Material(crypto.randomUUID(), "Leipzig", 32),
  new Material(crypto.randomUUID(), "Flax", 30),
  new Material(crypto.randomUUID(), "Jakarta", 34),
  new Material(crypto.randomUUID(), "York", 25),
  new Material(crypto.randomUUID(), "Torres", 27),

  new Material(crypto.randomUUID(), "Frankfurt", 36),
  new Material(crypto.randomUUID(), "Kent", 28),
  new Material(crypto.randomUUID(), "Kala", 24),
  new Material(crypto.randomUUID(), "Jaipur", 26),
  new Material(crypto.randomUUID(), "Palma BO", 40),
  new Material(crypto.randomUUID(), "Nakato", 32),
  new Material(crypto.randomUUID(), "Maracay", 34),
  new Material(crypto.randomUUID(), "Seoul", 34),
  new Material(crypto.randomUUID(), "Structure Ten", 34),
  new Material(crypto.randomUUID(), "Geneve", 29),
  new Material(crypto.randomUUID(), "Tigris Perla", 34),
];


export const Materials = {
  all: (): Material[] => {
    return values;
  },

  default: () : Material => {
  return values[0]
  },

  findById: (id: string): Material => {
    const material = values.find(e => e.id == id)
    if (!material)
      throw new Error("No material found for id = " + id)
    return material
  }
};
