export class Furniture {
  constructor(readonly id: string, readonly width: number, readonly cost: number) {}

  static all = (): Furniture[]  => {
    return [
      new Furniture(crypto.randomUUID(), 200, 16),
      new Furniture(crypto.randomUUID(), 300, 17),
      new Furniture(crypto.randomUUID(), 400, 18),
      new Furniture(crypto.randomUUID(), 500, 20),
      new Furniture(crypto.randomUUID(), 600, 21),
      new Furniture(crypto.randomUUID(), 700, 22),
      new Furniture(crypto.randomUUID(), 800, 23),
      new Furniture(crypto.randomUUID(), 900, 24),
      new Furniture(crypto.randomUUID(), 1000, 25),
      new Furniture(crypto.randomUUID(), 1100, 26),
      new Furniture(crypto.randomUUID(), 1200, 27),
      new Furniture(crypto.randomUUID(), 1300, 29),
      new Furniture(crypto.randomUUID(), 1400, 30),
      new Furniture(crypto.randomUUID(), 1500, 31),
      new Furniture(crypto.randomUUID(), 1600, 41),
      new Furniture(crypto.randomUUID(), 1700, 44),
    ]
  }

  static findCostByWidth = (width: number): number => {
    const furniture = Furniture.all().sort((a, b) => a.width - b.width).find(e => e.width >= width)
    if (!furniture)
      throw new Error("Invalid cost for widht: " + width)
    return furniture.cost
  }
}


