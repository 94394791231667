import { ED_COST_MULTIPLIER, WALL_MOUNT_COST } from "./constants";

export interface IEntry {
  readonly uuid: string

  readonly cost?: number
  readonly totalCost?: number
  readonly totalFinalCost?: number,
  readonly idx: number
}

export class Entry implements IEntry {
  constructor(readonly uuid: string, readonly idx: number) {
    this.uuid = uuid
    this.idx = idx
  }

  get totalCost(): number {
    return 0;
  }

  get totalFinalCost(): number {
    return this.totalCost * ED_COST_MULTIPLIER
  }

  get cost(): number {
    return 0;
  }

}
