import { ED_COST_MULTIPLIER, WALL_MOUNT_COST } from "../constants"
import { Entry, IEntry } from "../Entry"
import { ProfileColor } from "./ProfileColor"
import { Material, Materials } from "./Material"
import { Furniture } from "./Furniture"

export interface IEntryPlisse extends IEntry {
  readonly profile: ProfileColor,
  readonly material: Material,
  readonly height: number,
  readonly width: number,
  readonly amount: number,
  readonly wallMount: boolean,
  readonly furnitureCost: number,
}

export class EntryPlisse extends Entry {
  constructor(
    readonly uuid: string,
    readonly idx: number,
    readonly profile: ProfileColor,
    readonly material: Material,
    readonly height: number,
    readonly width: number,
    readonly amount: number,
    readonly wallMount: boolean,
  ) {
    super(uuid, idx)
  }

  get furnitureCost(): number {
    return Furniture.findCostByWidth(this.width)
  }

  get totalCost(): number {
    return this.cost * this.amount
  }

  get totalFinalCost(): number {
    return this.totalCost * ED_COST_MULTIPLIER
  }

  get cost(): number {
    const area = Math.max(0.5, this.width * this.height * 0.000001)
    return area * this.material.cost + this.furnitureCost * this.profile.priceMultiplier + (this.wallMount ? WALL_MOUNT_COST : 0)
  }

  static create(idx: number): Entry {
    return new EntryPlisse(
      crypto.randomUUID(),
      idx,
      ProfileColor.black,
      Materials.default(),
      500,
      500,
      1,
      false,
    )
  }

  static fromData(data: IEntryPlisse): Entry {
    return new EntryPlisse(
      data.uuid,
      data.idx,
      data.profile,
      data.material,
      data.height,
      data.width,
      data.amount,
      data.wallMount,
    )
  }
}

