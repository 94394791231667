
export class ProfileColor {
  static readonly white = new ProfileColor(1, "Белый", 1)
  static readonly brown = new ProfileColor(2, "Коричневый", 1)
  static readonly creamy = new ProfileColor(3, "Кремовый", 1)
  static readonly silver = new ProfileColor(4, "Серебро", 1)
  static readonly anthracite = new ProfileColor(5, "Антрацит", 1.2)
  static readonly golden = new ProfileColor(6, "Золотой дуб", 1.2)
  static readonly black = new ProfileColor(7, "Чёрный", 1.2)

  static readonly values = [
    ProfileColor.white, ProfileColor.brown, ProfileColor.creamy, ProfileColor.silver, ProfileColor.anthracite, ProfileColor.golden, ProfileColor.black
  ]

  private constructor(readonly id: number, readonly name: string, readonly priceMultiplier: number) {

  }

  static ofValue(value: string): ProfileColor {
    const id = Number.parseInt(value);
    return ProfileColor.values.find((v) => v.id == id) || ProfileColor.values[0]
  }
}
