import { ED_COST_MULTIPLIER } from "../../Models/constants";
import { Entry, IEntry } from "../../Models/Entry";
import { EntryComponentProps, EntryRendererProps } from "../EntryComponent";

interface IEntryMosquitoNetComponentProps extends EntryComponentProps {
  data: IEntryMosquitoNet
}

export class FrameType {
  static readonly frameType1 = new FrameType(1, "Рамка")
  static readonly frameType2 = new FrameType(2, "Рулон")
  static readonly frameType3 = new FrameType(3, "Дверь")

  static readonly values = [
    FrameType.frameType1, FrameType.frameType2, FrameType.frameType3
  ]

  private constructor(readonly id: number, readonly name: string) {

  }

  static ofValue(value: string): FrameType {
    const id = Number.parseInt(value);
    return FrameType.values.find((v) => v.id == id) || FrameType.values[0]
  }
}

export interface IEntryMosquitoNet extends IEntry {
  readonly amount: number,
  readonly frameType: FrameType,
  readonly width: number,
  readonly height: number,
  readonly holder: string,
  readonly color: string,
  readonly netType: string,
}

export class EntryMosquitoNet extends Entry {
  constructor(
    readonly uuid: string,
    readonly idx: number,
    readonly amount: number,
    readonly frameType: FrameType,
    readonly width: number,
    readonly height: number,
    readonly holder: string,
    readonly color: string,
    readonly netType: string,
  ) {
    super(uuid, idx)
  }

  get totalCost(): number {
    return this.cost * this.amount
  }

  get totalFinalCost(): number {
    return this.totalCost * ED_COST_MULTIPLIER
  }

  get cost(): number {
    const area = this.width * this.height * 0.000001
    return area * 90
  }

  static create(idx: number): Entry {
    return new EntryMosquitoNet(
      crypto.randomUUID(),
      idx,
      1,
      FrameType.frameType1,
      632,
      1980,
      "Универс",
      "Антрацит(Рулон)",
      "PetScreen(Серая)"
    )
  }

  static fromData(data: IEntryMosquitoNet): Entry {
    return new EntryMosquitoNet(
      data.uuid,
      data.idx,
      data.amount,
      data.frameType,
      data.width,
      data.height,
      data.holder,
      data.color,
      data.netType,
    )
  }
}

const EntryRenderer: React.FC<IEntryMosquitoNetComponentProps> = ({ data, onDelete, onUpdate }) => {
  return (
    <article>
      <div className='grid'>
        <label>
           Рамка/Рулон/Дверь
          <select id="control-type"
            value={data.frameType.id}
            onChange={e => onUpdate({ ...data, controlType: FrameType.ofValue(e.target.value) } as IEntry)}>
            <option value="">Select...</option>
            {FrameType.values.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Цвет
          <input type="text" value={data.color} onChange={e => onUpdate({ ...data, color: e.target.value } as IEntry)}></input>
        </label>
        <label>
          Зажим
          <input type="text" value={data.holder} onChange={e => onUpdate({ ...data, holder: e.target.value } as IEntry)}></input>
        </label>
        <label>
          Сетка
          <input type="text" value={data.netType} onChange={e => onUpdate({ ...data, netType: e.target.value } as IEntry)}></input>
        </label>
      </div>
      <div className="grid">
        <label>
          Ширина 
          <input type="number" value={data.width} onChange={e => onUpdate({ ...data, width: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
        <label>
          Высота
          <input type="number" value={data.height} onChange={e => onUpdate({ ...data, height: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
      </div>

      <hr />
      <div className='grid'>
        <p className='centered-checkbox'>Цена: <span>{data.cost?.toFixed(2)}</span></p>
        <p className='centered-checkbox'>Всего: <span>{data.totalCost?.toFixed(2)}</span></p>
        <p className='centered-checkbox'>Всего (Ed?): <span>{data.totalFinalCost?.toFixed(2)}</span></p>
        <button onClick={(_e) => onDelete(data)}>Удалить</button>
      </div>
    </article >
  );
}

export const MosquitoNetEntires: React.FC<EntryRendererProps> = ({ entries, onDelete, onUpdate }) => {

  return (
    <div>
      {entries.filter(e => e instanceof EntryMosquitoNet).sort((a, b) => a.idx - b.idx).map(e => <EntryRenderer
        key={e.uuid}
        data={e as IEntryMosquitoNet}
        onDelete={(e) => onDelete(e)}
        onUpdate={(e) => onUpdate(e)}></EntryRenderer>
      )}
    </div>
  );
}
