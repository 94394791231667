import { ED_COST_MULTIPLIER } from "../../Models/constants";
import { Entry, IEntry } from "../../Models/Entry";
import { EntryComponentProps, EntryRendererProps } from "../EntryComponent";

interface IEntryBestaDNComponentProps extends EntryComponentProps {
  data: IEntryBestaDN
}

export class SupportType {
  static readonly t32 = new SupportType(1, "32")
  static readonly t36 = new SupportType(2, "36")

  static readonly values = [
    SupportType.t32, SupportType.t36
  ]

  private constructor(readonly id: number, readonly name: string) {

  }

  static ofValue(value: string): ControlType {
    const id = Number.parseInt(value);
    return SupportType.values.find((v) => v.id == id) || SupportType.values[0]
  }
}

export class ChainType {
  static readonly pvc = new ChainType(1, "PVC")
  static readonly metall = new ChainType(2, "Metall")

  static readonly values = [
    ChainType.pvc, ChainType.metall
  ]

  private constructor(readonly id: number, readonly name: string) {

  }

  static ofValue(value: string): ChainType {
    const id = Number.parseInt(value);
    return ChainType.values.find((v) => v.id == id) || ChainType.values[0]
  }
}


export class ControlType {
  static readonly l = new ControlType(1, "L")
  static readonly r = new ControlType(2, "R")

  static readonly values = [
    ControlType.l, ControlType.r
  ]

  private constructor(readonly id: number, readonly name: string) {

  }

  static ofValue(value: string): ControlType {
    const id = Number.parseInt(value);
    return ControlType.values.find((v) => v.id == id) || ControlType.values[0]
  }
}

export class Type {
  static readonly Besta = new Type(1, "Besta")
  static readonly Besta02 = new Type(2, "Besta 02 Коричневый")
  static readonly Besta04 = new Type(3, "Besta 04 Серебро")
  static readonly Besta22 = new Type(4, "Besta 22 Махагон")
  static readonly Besta23 = new Type(5, "Besta 23 Темно-серый")
  static readonly Besta30 = new Type(6, "Besta 30 Орех")
  static readonly Besta34 = new Type(7, "Besta 34 Темный дуб")
  static readonly Besta47 = new Type(8, "Besta 47 Светлый дуб")
  static readonly Besta48 = new Type(9, "Besta 48 Золотой дуб")

  static readonly values = [
    Type.Besta, Type.Besta02, Type.Besta04, Type.Besta22,
    Type.Besta23, Type.Besta30, Type.Besta34, Type.Besta47, Type.Besta48
  ]

  private constructor(readonly id: number, readonly name: string) {

  }

  static ofValue(value: string): Type {
    const id = Number.parseInt(value);
    return Type.values.find((v) => v.id == id) || Type.values[0]
  }
}

export interface IEntryBestaDN extends IEntry {
  readonly amount: number,
  readonly controlType: ControlType,
  readonly type: Type,
  readonly color: string,
  readonly materialWidth: number,
  readonly furnitureWidth: number,
  readonly furnitureHeight1: number,
  readonly furnitureHeight2: number,
  readonly chainType: ChainType,
  readonly supportType: SupportType,
}

export class EntryBestaDN extends Entry {
  constructor(
    readonly uuid: string,
    readonly idx: number,
    readonly amount: number,
    readonly controlType: ControlType,
    readonly type: Type,
    readonly color: string,
    readonly materialWidth: number,
    readonly furnitureWidth: number,
    readonly furnitureHeight1: number,
    readonly furnitureHeight2: number,
    readonly chainType: ChainType,
    readonly supportType: SupportType,
  ) {
    super(uuid, idx)
  }

  get totalCost(): number {
    return this.cost * this.amount
  }

  get totalFinalCost(): number {
    return this.totalCost * ED_COST_MULTIPLIER
  }

  get cost(): number {
    const area = Math.max(0.5, this.furnitureWidth)
    return 56
  }

  static create(idx: number): Entry {
    return new EntryBestaDN(
      crypto.randomUUID(),
      idx,
      1,
      ControlType.l,
      Type.Besta,
      "ВН 1306",
      1246,
      1291,
      1203,
      1185,
      ChainType.pvc,
      SupportType.t32,
    )
  }

  static fromData(data: IEntryBestaDN): Entry {
    return new EntryBestaDN(
      data.uuid,
      data.idx,
      data.amount,
      data.controlType,
      data.type,
      data.color,
      data.materialWidth,
      data.furnitureWidth,
      data.furnitureHeight1,
      data.furnitureHeight2,
      data.chainType,
      data.supportType,
    )
  }
}

const EntryRenderer: React.FC<IEntryBestaDNComponentProps> = ({ data, onDelete, onUpdate }) => {
  return (
    <article>
      <div className='grid'>
        <label>
          Управление
          <select id="control-type"
            value={data.controlType.id}
            onChange={e => onUpdate({ ...data, controlType: ControlType.ofValue(e.target.value) } as IEntry)}>
            <option value="">Select...</option>
            {ControlType.values.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Тип
          <select id="type"
            value={data.type.id}
            onChange={e => onUpdate({ ...data, type: Type.ofValue(e.target.value) } as IEntry)}>
            <option value="">Select...</option>
            {Type.values.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Цвет
          <input type="text" value={data.color} onChange={e => onUpdate({ ...data, color: e.target.value } as IEntry)}></input>
        </label>
      </div>
      <div className="grid">
        <label>
          Ширина ткани, mm
          <input type="number" value={data.materialWidth} onChange={e => onUpdate({ ...data, materialWidth: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
        <label>
          Ширина кассеты, mm
          <input type="number" value={data.furnitureWidth} onChange={e => onUpdate({ ...data, furnitureWidth: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
        <label>
          Высота кассеты 1
          <input type="number" value={data.furnitureHeight1} onChange={e => onUpdate({ ...data, furnitureHeight1: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>

        <label>
          Высота кассеты 2
          <input type="number" value={data.furnitureHeight2} onChange={e => onUpdate({ ...data, furnitureHeight2: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
      </div>

      <div className='grid'>
        <label>
          Направляющие
          <select id="supportType"
            value={data.supportType.id}
            onChange={e => onUpdate({ ...data, type: SupportType.ofValue(e.target.value) } as IEntry)}>
            <option value="">Select...</option>
            {SupportType.values.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Цепочка PVC/Metall
          <select id="chainType"
            value={data.chainType.id}
            onChange={e => onUpdate({ ...data, type: ChainType.ofValue(e.target.value) } as IEntry)}>
            <option value="">Select...</option>
            {ChainType.values.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Количество
          <input type="number" value={data.amount} onChange={e => onUpdate({ ...data, amount: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
      </div >
      <hr />
      <div className='grid'>
        <p className='centered-checkbox'>Цена: <span>{data.cost?.toFixed(2)}</span></p>
        <p className='centered-checkbox'>Всего: <span>{data.totalCost?.toFixed(2)}</span></p>
        <p className='centered-checkbox'>Всего (Ed?): <span>{data.totalFinalCost?.toFixed(2)}</span></p>
        <button onClick={(_e) => onDelete(data)}>Удалить</button>
      </div>
    </article >
  );
}

export const BestaEntriesDN: React.FC<EntryRendererProps> = ({ entries, onDelete, onUpdate }) => {

  return (
    <div>
      {entries.filter(e => e instanceof EntryBestaDN).sort((a, b) => a.idx - b.idx).map(e => <EntryRenderer
        key={e.uuid}
        data={e as IEntryBestaDN}
        onDelete={(e) => onDelete(e)}
        onUpdate={(e) => onUpdate(e)}></EntryRenderer>
      )}
    </div>
  );
}
