import { IEntry } from "../../Models/Entry";
import { EntryPlisse, IEntryPlisse } from "../../Models/Plisse";
import { Materials } from "../../Models/Plisse/Material"
import { ProfileColor } from "../../Models/Plisse/ProfileColor";
import { EntryComponentProps, EntryRendererProps } from "../EntryComponent";

interface IEntryPlisseComponentProps extends EntryComponentProps {
  data: IEntryPlisse
}


const EntryRenderer: React.FC<IEntryPlisseComponentProps> = ({ data, onDelete, onUpdate }) => {
  return (
    <article>
      <div className='grid'>
        <label>
          Цвет профиля
          <select id="profile"
            value={data.profile.id}
            onChange={e => onUpdate({ ...data, profile: ProfileColor.ofValue(e.target.value) } as IEntry)}>
            <option value="">Select...</option>
            {ProfileColor.values.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Материал
          <select id="material"
            value={data.material.id}
            onChange={e => onUpdate({ ...data, material: Materials.findById(e.target.value) } as IEntry)}>
            <option value="">Select...</option>
            {Materials.all().map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Цена механизма
          <input type="number" value={data.furnitureCost} disabled ></input>
        </label>
        <label>
          Цена ткани
          <input type="number" value={data.material.cost} disabled ></input>
        </label>
      </div>

      <div className='grid'>
        <label>
          Высота, mm
          <input type="number" min={1700} value={data.height} onChange={e => onUpdate({ ...data, height: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
        <label>
          Ширина, mm
          <input type="number" min={150} value={data.width} onChange={e => onUpdate({ ...data, width: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
        <label className='centered-checkbox'>
          <input type="checkbox" checked={data.wallMount} onChange={e => onUpdate({ ...data, wallMount: e.target.checked } as IEntry)}></input>
          Настенный кронштейн
        </label>
        <label>
          Количество
          <input type="number" value={data.amount} onChange={e => onUpdate({ ...data, amount: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
      </div >
      <hr />
      <div className='grid'>
        <p className='centered-checkbox'>Цена: <span>{data.cost?.toFixed(2)}</span></p>
        <p className='centered-checkbox'>Всего: <span>{data.totalCost?.toFixed(2)}</span></p>
        <p className='centered-checkbox'>Всего (Ed?): <span>{data.totalFinalCost?.toFixed(2)}</span></p>
        <button onClick={(_e) => onDelete(data)}>Удалить</button>
      </div>
    </article >
  );
}

export const PlisseEntries: React.FC<EntryRendererProps> = ({ entries, onDelete, onUpdate }) => {

  return (
    <div>
      {entries.filter(e => e instanceof EntryPlisse).sort((a, b) => a.idx - b.idx).map(e => <EntryRenderer
        key={e.uuid}
        data={e as IEntryPlisse}
        onDelete={(e) => onDelete(e)}
        onUpdate={(e) => onUpdate(e)}></EntryRenderer>
      )}
    </div>
  );
}
